<template>
  <div ref="colcon">
    <button @click="$refs['modal-new'].isShown = true" class="rounded text-gray-100 px-3 py-1
    float-right hover:shadow-inner align-middle inline-flex items-center
    bg-blue-500 hover:bg-blue-700">
      Neue Spalte</button>
    <table class="mt-5 min-w-full leading-normal">
      <tr>
        <th class="px-5 py-2 border-b-2 border-gray-200 text-left text-xs font-semibold
              text-gray-600 tracking-wider w-40">
        </th>
        <th class="px-5 py-2 border-b-2 border-gray-200 text-left text-xs font-semibold
              text-gray-600 tracking-wider w-2/12">
          Name
        </th>
        <th class="px-5 py-2 border-b-2 border-gray-200 text-left text-xs font-semibold
              text-gray-600 tracking-wider w-3/12">
          Karten
        </th>
        <th class="px-5 py-2 border-b-2 border-gray-200 text-left text-xs font-semibold
              text-gray-600 tracking-wider w-5/12">
          Status
        </th>
        <th class="px-5 py-2 border-b-2 border-gray-200 text-left text-xs font-semibold
              text-gray-600 tracking-wider w-2/12">
          Aktion
        </th>
      </tr>
      <draggable
        tag="tbody"
        handle=".handle"
        :list="orderedColumns"
        :animation="200"
        drag-class="drag-column"
        ghost-class="ghost-column"
        :move="onMove"
        @end="endMove"
        :forceFallback="true"
      >
        <tr @click="activeColumn = column"
            v-for="column in orderedColumns" :key="column.id">
          <td class="cursor-move px-5 py-2 border-b border-gray-200 bg-white text-sm w-40 handle">
            <svg class="w-4 h-4" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M7 16V4m0 0L3 8m4-4l4 4m6 0v12m0 0l4-4m-4 4l-4-4" /></svg>
          </td>
          <td class="px-5 py-2 border-b border-gray-200 bg-white text-sm w-3/12">
            {{ column.name }}</td>
          <td class="px-5 py-2 border-b border-gray-200 bg-white text-sm w-2/12">
            {{ typeof column.cards === 'undefined' ? 0 : column.cards.length }}</td>
          <td class="px-5 py-2 border-b border-gray-200 bg-white text-sm w-5/12">
            <span v-if="column.status !== null">{{column.status.status}} -
              {{ column.status.name }} </span>
            <span v-if="column.status === null">Kein Status zugeordnet</span>
          </td>
          <td class="px-5 py-2 flex-grow border-b border-gray-200 flex
          bg-white text-sm">
            <span @click="activeColumn = column; $refs['modal-edit'].isShown = true"
                  class="cursor-pointer text-blue-500 hover:text-blue-700 mr-3 inline-flex">
            <svg class="w-4 h-4 mr-1 mt-0.5" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor"><path d="M17.414 2.586a2 2 0 00-2.828 0L7 10.172V13h2.828l7.586-7.586a2 2 0 000-2.828z" /><path fill-rule="evenodd" d="M2 6a2 2 0 012-2h4a1 1 0 010 2H4v10h10v-4a1 1 0 112 0v4a2 2 0 01-2 2H4a2 2 0 01-2-2V6z" clip-rule="evenodd" /></svg>
            Bearbeiten</span>
            <span @click="activeColumn = column; $refs['modal-delete'].isShown = true;
            deleteErrors = []"
                  class="cursor-pointer text-red-500 hover:text-red-700 inline-flex">
            <svg class="w-4 h-4 mt-0.5 mr-1" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16" /></svg>
            Entfernen</span>
          </td>
        </tr>
      </draggable>
    </table>
    <ModalSm ref="modal-edit" customClass="h-30 w-80">
      <template v-slot:header>
        Spalte bearbeiten
      </template>
      <template v-slot:body>
        <InputCom name="columnName" label="Name" v-model="slotColumn.name"
                  :val="slotColumn.name"></InputCom>
        <div>
          <div class="border focus-within:border-blue-500 focus-within:text-blue-500 transition-all
              duration-500 relative rounded p-1 mt-5 z-0">
            <label for="status_select" class="-mt-4 absolute tracking-wider px-1 uppercase text-xs
          bg-white text-gray-600">Status</label>
            <div>
        <v-select v-model="slotColumn.status_id" :options="statusOptions"
                  :reduce="label => label.value"
                  height="20"
                  :appendToBody="true"
                  id="status_select"
                  class="text-sm whitespace-nowrap text-gray-900 outline-none
                         block h-full w-full border-none"
        ></v-select>
            </div>
          </div>
        </div>
      </template>
      <template v-slot:footer>
        <AnimatedButton @click.native="columnUpdate('button-'+slotColumn.id)"
                        :ref="'button-'+slotColumn.id"
                        :pause="750"
                        @reset="$refs['modal-edit'].isShown = false"
                        class="ml-2 float-right">
        </AnimatedButton>
      </template>
    </ModalSm>
    <ModalSm ref="modal-new" customClass="h-30 w-80">
      <template v-slot:header>
        Spalte hinzufügen
      </template>
      <template v-slot:body>
        <InputCom name="columnName" label="Name" v-model="slotColumn.name"
                  :val="slotColumn.name"></InputCom>
      </template>
      <template v-slot:footer>
        <AnimatedButton @click.native="columnStore('button-'+slotColumn.id)"
                        :ref="'button-'+slotColumn.id"
                        :pause="750"
                        @reset="$refs['modal-new'].isShown = false"
                        class="ml-2 float-right">
        </AnimatedButton>
      </template>
    </ModalSm>
    <ModalSm ref="modal-delete" customClass="h-30 w-100">
      <template v-slot:header>
        Spalte löschen
      </template>
      <template v-slot:body>
        <p>Möchten Sie die Spalte <span class="text-red-500">{{ slotColumn.name }}
        </span> wirklich löschen?</p>
      </template>
      <template v-slot:footer>
        <AnimatedButton @click.native="columnDelete('button-'+slotColumn.id)"
                        :ref="'button-'+slotColumn.id"
                        on-initial="Ja, Spalte löschen"
                        on-error="Fehlgeschlagen!"
                        on-success="Erfolgreich gelöscht!"
                        initial-class="bg-red-500 hover:bg-red-700"
                        :pause="750"
                        @reset="$refs['modal-delete'].isShown = false"
                        class="ml-2 float-right">
        </AnimatedButton>
      </template>
    </ModalSm>
  </div>
</template>

<script>
import draggable from 'vuedraggable';
import ModalSm from '@/components/Util/ModalSm.vue';
import InputCom from '@/components/Util/InputCom.vue';
import AnimatedButton from '@/components/Util/AnimatedButton.vue';

export default {
  name: 'Columns',
  components: {
    draggable,
    ModalSm,
    InputCom,
    AnimatedButton,
  },
  data() {
    return {
      activeColumn: {},
      slotColumn: {},
      columns: [],
      deleteErrors: [],
      loadState: 'initial',
      statusList: [],
    };
  },
  computed: {
    orderedColumns() {
      const col = this.columns;
      return col.sort((a, b) => a.position - b.position);
    },
    statusOptions() {
      const options = [];
      this.statusList.forEach((s) => {
        options.push({ value: s.id, label: `${s.status} - ${s.name}` });
      });
      options.splice(0, 1);
      return options;
    },
  },
  methods: {
    spit(col) {
      this.columns.splice(col.position, 1);
    },
    index() {
      this.$axios.get('columns',
        { headers: { Authorization: `Bearer ${this.$store.getters['auth/authToken']}` } })
        .then((response) => {
          this.columns = response.data.data;
        })
        .catch(() => {
          // console.log(error);
        });
    },
    statusIndex() {
      this.$axios.get('status',
        { headers: { Authorization: `Bearer ${this.$store.getters['auth/authToken']}` } })
        .then((response) => {
          this.statusList = response.data.data;
        })
        .catch(() => {
          // console.log(error);
        });
    },
    columnUpdate(button) {
      console.log(this.slotColumn);
      if (typeof this.$refs[button] !== 'undefined') this.$refs[button].loadState = 'loading';
      this.$axios.put(`columns/${this.slotColumn.id}`, this.slotColumn,
        { headers: { Authorization: `Bearer ${this.$store.getters['auth/authToken']}` } })
        .then((response) => {
          if (typeof this.$refs[button] !== 'undefined') this.$refs[button].loadState = 'success';
          console.log(response.data.data);
          // this.activeColumn = response.data.data;
          this.$toast.open({
            message: 'Erfolgreich gespeichert!',
            type: 'success',
          });
        })
        .catch((e) => {
          if (typeof this.$refs[button] !== 'undefined') this.$refs[button].loadState = 'error';
          this.$toast.open({
            message: e,
            type: 'error',
          });
        });
    },
    columnStore(button) {
      if (typeof this.$refs[button] !== 'undefined') this.$refs[button].loadState = 'loading';
      this.$axios.post('columns', this.slotColumn,
        { headers: { Authorization: `Bearer ${this.$store.getters['auth/authToken']}` } })
        .then((response) => {
          if (typeof this.$refs[button] !== 'undefined') this.$refs[button].loadState = 'success';
          this.columns.push(response.data.data);
          this.$toast.open({
            message: 'Erfolgreich gespeichert!',
            type: 'success',
          });
        })
        .catch((error) => {
          if (typeof this.$refs[button] !== 'undefined') this.$refs[button].loadState = 'error';
          this.$toast.open({
            message: error.response.data,
            type: 'error',
          });
        });
    },
    columnDelete(button) {
      if (typeof this.$refs[button] !== 'undefined') this.$refs[button].loadState = 'loading';
      this.$axios.delete(`columns/${this.slotColumn.id}`,
        { headers: { Authorization: `Bearer ${this.$store.getters['auth/authToken']}` } })
        .then(() => {
          this.columns.splice(this.slotColumn.position, 1);
          this.reorderOnRemoved(this.slotColumn.position);
          if (typeof this.$refs[button] !== 'undefined') this.$refs[button].loadState = 'success';
          this.$toast.open({
            message: 'Erfolgreich gelöscht!',
            type: 'success',
          });
        })
        .catch((error) => {
          if (typeof this.$refs[button] !== 'undefined') this.$refs[button].loadState = 'error';
          this.$toast.open({
            message: error.response.data.error,
            type: 'error',
          });
        });
    },
    onMove(e) {
      this.slotColumn = e.draggedContext.element;
    },
    endMove(e) {
      this.slotColumn.position = e.newIndex;
      this.reorder(this.slotColumn, e.oldIndex);
      this.columnUpdate();
    },
    reorder(col, oldIndex) {
      /* eslint-disable no-param-reassign */
      this.columns.forEach((c) => {
        if (col.id !== c.id && oldIndex > col.position && c.position >= col.position
          && c.position < oldIndex) {
          c.position = Number(c.position) + 1;
        } else if (col.id !== c.id && oldIndex < c.position && c.position <= col.position
          && c.position > oldIndex) {
          c.position = Number(c.position) - 1;
        }
      });
      col.position = Number(col.position);
      /* eslint-enable no-param-reassign */
    },
    reorderOnRemoved(oldIndex) {
      this.columns.forEach((c) => {
        if (c.position > oldIndex) {
          // eslint-disable-next-line no-param-reassign
          c.position = parseInt(c.position, 10) - 1;
        }
      });
    },
    setAct(val) {
      this.activeColumn = val;
    },
    replace(oldCol, newCol) {
      // eslint-disable-next-line no-param-reassign
      oldCol = newCol;
    },
  },
  beforeMount() {
    this.index();
    this.statusIndex();
  },
  mounted() {
    console.log(this.$refs.colcon.getBoundingClientRect());
    window.Echo.channel('column.updated').listen('ColumnUpdated', (e) => {
      const index = this.columns.findIndex((c) => c.id === e.data.id);
      const col = this.columns.find((element) => element.id === e.data.id);
      this.reorder(e.data, col.position);
      if (index >= 0) this.columns.splice(index, 1, e.data);
      else this.columns.push(e.data);
    });
  },
  watch: {
    activeColumn: {
      immediate: true,
      deep: true,
      handler(value) {
        this.slotColumn = { ...value };
      },
    },
  },
};
</script>

<style scoped>
.ghost-column {
  opacity: 0.5;
  background: #F7FAFC;
  border: 1px solid #4299e1;
}

.drag-column {
  opacity: 1;
}

.sortable-fallback{
  visibility:hidden;
}

.toggle__dot {
  top: -.25rem;
  left: -.25rem;
  transition: all 0.3s ease-in-out;
}
</style>

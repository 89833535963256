<template>
  <div class="flex justify-center flex-grow">
    <div class="mt-10 border w-9/12 mx-auto shadow rounded-lg p-6 mb-10">
      <h1 class="text-center text-3xl">Verwaltung</h1>
      <nav class="flex flex-col sm:flex-row">
        <button @click="setActiveTab('Plentymarkets')" class="text-gray-600 py-4 px-6 block
        hover:text-blue-500 focus:outline-none"
                :class="{'text-blue-500 border-b-2 font-medium border-blue-500':
          activeTab == 'Plentymarkets'}">
          Plentymarkets
        </button>
        <button @click="setActiveTab('Benutzer')" class="text-gray-600 py-4 px-6 block
        hover:text-blue-500 focus:outline-none"
          :class="{'text-blue-500 border-b-2 font-medium border-blue-500':
          activeTab == 'Benutzer'}">
          Benutzer
        </button>
<!--        <button @click="setActiveTab('Boards')" class="text-gray-600 py-4 px-6 block-->
<!--        hover:text-blue-500 focus:outline-none"-->
<!--                         :class="{'text-blue-500 border-b-2 font-medium border-blue-500':-->
<!--          activeTab == 'Boards'}">-->
<!--        Boards-->
<!--        </button>-->
        <button @click="setActiveTab('Spalten')" class="text-gray-600 py-4 px-6 block
        hover:text-blue-500 focus:outline-none"
          :class="{'text-blue-500 border-b-2 font-medium border-blue-500':
          activeTab == 'Spalten'}">
          Spalten
        </button>
        <button @click="setActiveTab('Vorlagen')" class="text-gray-600 py-4 px-6 block
        hover:text-blue-500 focus:outline-none"
                :class="{'text-blue-500 border-b-2 font-medium border-blue-500':
          activeTab == 'Vorlagen'}">
          Vorlagen
        </button>
        <button @click="setActiveTab('Status')" class="text-gray-600 py-4 px-6 block
        hover:text-blue-500 focus:outline-none"
                :class="{'text-blue-500 border-b-2 font-medium border-blue-500':
          activeTab == 'Status'}">
          Status
        </button>
        <button @click="setActiveTab('Reklamation')" class="text-gray-600 py-4 px-6 block
        hover:text-blue-500 focus:outline-none"
                :class="{'text-blue-500 border-b-2 font-medium border-blue-500':
          activeTab == 'Reklamation'}">
          Reklamation
        </button>
      </nav>
      <div v-show="activeTab == 'Plentymarkets'">
        <Plentymarkets></Plentymarkets>
      </div>
      <div v-show="activeTab == 'Benutzer'">
        <Users></Users>
      </div>
      <div v-if="activeTab == 'Spalten'">
        <Columns></Columns>
      </div>
      <div v-if="activeTab == 'Vorlagen'">
        <Templates></Templates>
      </div>
      <div v-if="activeTab == 'Status'">
        <Status></Status>
      </div>
      <div v-if="activeTab == 'Reklamation'">
        <Reklamation></Reklamation>
      </div>
    </div>
  </div>
</template>

<script>
import Users from '@/components/admin/Users.vue';
import Columns from '@/components/admin/Columns.vue';
import Plentymarkets from '@/components/admin/Plentymarkets.vue';
import Templates from '@/components/admin/Templates.vue';
import Status from '@/components/admin/Status.vue';
import Reklamation from '@/components/admin/Reklamation.vue';

export default {
  name: 'Admin',
  components: {
    Reklamation,
    Status,
    Templates,
    Users,
    Columns,
    Plentymarkets,
  },
  data() {
    return {
      activeTab: 'Plentymarkets',
      e: '',
      columns: [],
    };
  },
  methods: {
    setActiveTab(tabName) {
      this.activeTab = tabName;
    },
  },
};
</script>

<style scoped>

</style>

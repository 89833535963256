<template>
  <div class="flex-1">
    <div class="flex">
      <div class="flex-1">
        <h4>Reklamationsgrund</h4>
      </div>
      <div class="flex-1">
        <button @click="$refs['modal-new'].isShown = true; activeReason = {is_active: true}"
              class="rounded text-gray-100 px-3 py-1
      float-right hover:shadow-inner align-middle inline-flex items-center
      bg-blue-500 hover:bg-blue-700">
        Neuer Grund</button>
      </div>
    </div>
    <table class="mt-5 min-w-full leading-normal">
      <tr>
        <th class="px-5 py-2 border-b-2 border-gray-200 text-left text-xs font-semibold
              text-gray-600 tracking-wider w-30">
          Name
        </th>
        <th class="px-5 py-2 border-b-2 border-gray-200 text-left text-xs font-semibold
              text-gray-600 tracking-wider w-30">
          Plenty-Position
        </th>
        <th class="px-5 py-2 border-b-2 border-gray-200 text-left text-xs font-semibold
              text-gray-600 tracking-wider w-23">
          Aktion
        </th>
      </tr>
      <tr>
        <td class="px-5 py-2 border-b border-gray-200 bg-white text-sm w-30 text-center"
            v-if="reasons.length === 0" colspan="3">Keine Daten vorhanden</td>
      </tr>
      <tr v-for="reason in reasons" :key="reason.id">
        <td class="px-5 py-2 border-b border-gray-200 bg-white text-sm w-30">
          {{ reason.name }}
        </td>
        <td class="px-5 py-2 border-b border-gray-200 bg-white text-sm w-30">
          {{ reason.position }}
        </td>
        <td class="flex px-5 py-2 border-b inline-block border-gray-200 bg-white text-sm w-30">
          <span @click="activeReason = reason; $refs['modal-edit'].isShown = true;"
                class="flex cursor-pointer text-blue-500 hover:text-blue-700 mr-2">
            <svg class="w-4 h-4 mr-1 mt-0.5" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor"><path d="M17.414 2.586a2 2 0 00-2.828 0L7 10.172V13h2.828l7.586-7.586a2 2 0 000-2.828z" /><path fill-rule="evenodd" d="M2 6a2 2 0 012-2h4a1 1 0 010 2H4v10h10v-4a1 1 0 112 0v4a2 2 0 01-2 2H4a2 2 0 01-2-2V6z" clip-rule="evenodd" /></svg>
            Bearbeiten</span>
          <span @click="activeReason = reason; $refs['modal-delete'].isShown = true;"
                class="inline cursor-pointer text-red-500 hover:text-red-700 border-l mr-2 pl-2">
            <svg class="inline w-4 h-4 -mt-1 mr-1" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16" /></svg>
            Entfernen</span>
        </td>
      </tr>
    </table>
    <ModalSm ref="modal-new" customClass="h-30 w-80">
      <template v-slot:header>
        Grund hinzufügen
      </template>
      <template v-slot:body>
        <InputCom name="name" label="Name" v-model="slotReason.name"
                  :val="slotReason.name"></InputCom>
        <InputCom name="position" label="Plenty-Position" v-model="slotReason.position"
                  :val="slotReason.position"></InputCom>
      </template>
      <template v-slot:footer>
        <AnimatedButton @click.native="store('button-new-'+slotReason.id)"
                        :ref="'button-new-'+slotReason.id"
                        :pause="750"
                        @reset="$refs['modal-new'].isShown = false"
                        class="ml-2 float-right">
        </AnimatedButton>
      </template>
    </ModalSm>
    <ModalSm ref="modal-edit" customClass="h-30 w-80">
      <template v-slot:header>
        Reklamationsgrund bearbeiten
      </template>
      <template v-slot:body>
        <InputCom name="name" label="Name" v-model="slotReason.name"
                  :val="slotReason.name"></InputCom>
        <InputCom name="position" label="Plenty-Position" v-model="slotReason.position"
                  :val="slotReason.position"></InputCom>
      </template>
      <template v-slot:footer>
        <AnimatedButton @click.native="update('button-edit-'+slotReason.id)"
                        :ref="'button-edit-'+slotReason.id"
                        :pause="750"
                        @reset="$refs['modal-edit'].isShown = false"
                        class="ml-2 float-right">
        </AnimatedButton>
      </template>
    </ModalSm>
    <ModalSm ref="modal-delete" customClass="h-30 w-96">
      <template v-slot:header>
        Grund löschen
      </template>
      <template v-slot:body>
        <p>Möchten Sie den Grund <span class="text-red-500">{{ slotReason.name }}
        </span> wirklich löschen?</p>
      </template>
      <template v-slot:footer>
        <AnimatedButton @click.native="destroy('button-delete-'+slotReason.id)"
                        :ref="'button-delete-'+slotReason.id"
                        on-initial="Ja, Grund löschen"
                        on-error="Fehlgeschlagen!"
                        on-success="Erfolgreich gelöscht!"
                        initial-class="bg-red-500 hover:bg-red-700"
                        :pause="750"
                        @reset="$refs['modal-delete'].isShown = false"
                        class="ml-2 float-right">
        </AnimatedButton>
      </template>
    </ModalSm>
  </div>
</template>

<script>
import ModalSm from '@/components/Util/ModalSm.vue';
import AnimatedButton from '@/components/Util/AnimatedButton.vue';
import InputCom from '@/components/Util/InputCom.vue';

export default {
  name: 'Reklamationsgrund',
  components: {
    AnimatedButton,
    ModalSm,
    InputCom,
  },
  data() {
    return {
      reasons: {},
      activeReason: {},
      slotReason: {},
    };
  },
  methods: {
    index() {
      this.$axios.get('order-return-reasons',
        { headers: { Authorization: `Bearer ${this.$store.getters['auth/authToken']}` } })
        .then((response) => {
          this.reasons = response.data;
        })
        .catch(() => {
          // console.log(error);
        });
    },
    update(button) {
      if (typeof this.$refs[button] !== 'undefined') this.$refs[button].loadState = 'loading';
      this.$axios.put(`order-return-reasons/${this.slotReason.id}`, this.slotReason,
        { headers: { Authorization: `Bearer ${this.$store.getters['auth/authToken']}` } })
        .then((response) => {
          if (typeof this.$refs[button] !== 'undefined') this.$refs[button].loadState = 'success';
          const index = this.reasons.findIndex((u) => u.id === response.data.id);
          this.reasons.splice(index, 1, response.data);
          this.$toast.open({
            message: 'Erfolgreich gespeichert!',
            type: 'success',
          });
        })
        .catch((e) => {
          if (typeof this.$refs[button] !== 'undefined') this.$refs[button].loadState = 'error';
          this.$toast.open({
            message: e,
            type: 'error',
          });
        });
    },
    store(button) {
      if (typeof this.$refs[button] !== 'undefined') this.$refs[button].loadState = 'loading';
      this.$axios.post('order-return-reasons', this.slotReason,
        { headers: { Authorization: `Bearer ${this.$store.getters['auth/authToken']}` } })
        .then((response) => {
          if (typeof this.$refs[button] !== 'undefined') this.$refs[button].loadState = 'success';
          this.reasons.push(response.data);
          this.$toast.open({
            message: 'Erfolgreich gespeichert!',
            type: 'success',
          });
        })
        .catch((error) => {
          if (typeof this.$refs[button] !== 'undefined') this.$refs[button].loadState = 'error';
          this.$toast.open({
            message: error.response.data,
            type: 'error',
          });
        });
    },
    destroy(button) {
      if (typeof this.$refs[button] !== 'undefined') this.$refs[button].loadState = 'loading';
      this.$axios.delete(`order-return-reasons/${this.activeReason.id}`,
        { headers: { Authorization: `Bearer ${this.$store.getters['auth/authToken']}` } })
        .then(() => {
          const index = this.reasons.findIndex((u) => u.id === this.activeReason.id);
          this.reasons.splice(index, 1);
          if (typeof this.$refs[button] !== 'undefined') this.$refs[button].loadState = 'success';
          this.$toast.open({
            message: 'Erfolgreich gelöscht!',
            type: 'success',
          });
        })
        .catch((error) => {
          if (typeof this.$refs[button] !== 'undefined') this.$refs[button].loadState = 'error';
          this.$toast.open({
            message: error.response.data.error,
            type: 'error',
          });
        });
    },
  },
  mounted() {
    this.index();
  },
  watch: {
    activeReason(value) {
      this.slotReason = { ...value };
    },
  },
};
</script>

<style scoped>

</style>

<template>
  <div class="mt-5 w-full min-h-56 max-h-full overflow-auto">
    <p class="mb-2">Letzte Übertragung:
      {{
        lastUpdatedAt | luxon({
          input: "iso",
          output: 'dd.MM.yyyy HH:mm:ss',
        })
      }} Uhr</p>
    <AnimatedButton @click.native="retrieveOrders('button-order')" :pause="1000"
                    ref="button-order"
                    on-initial="Aufträge importieren"
                    on-success="Erfolgreich!"
                    on-error="Fehler!"
                    @reset="loadState = 'initial';"
                    class="">
    </AnimatedButton>
    <h4 class="mt-5">Importierte Aufträge</h4>
    <table class="min-w-full leading-normal">
      <thead>
        <tr>
          <th class="px-5 py-2 border-b-2 border-gray-200 text-left text-xs font-semibold
                text-gray-600 tracking-wider">
            Auftragsnummer
          </th>
          <th class="px-5 py-2 border-b-2 border-gray-200 text-left text-xs font-semibold
                text-gray-600 tracking-wider">
            Kunde
          </th>
          <th class="px-5 py-2 border-b-2 border-gray-200 text-left text-xs font-semibold
                text-gray-600 tracking-wider">
            Erstellt am
          </th>
          <th class="px-5 py-2 border-b-2 border-gray-200 text-left text-xs font-semibold
                text-gray-600 tracking-wider">
            Karten
          </th>
        </tr>
      </thead>
      <tbody class="w-full">
        <tr v-if="orders.data.length === 0">
          <td colspan="4" class="px-5 py-2 border-b border-gray-200 bg-white text-center
          text-sm">
            Keine Aufträge</td>
        </tr>
        <tr
            v-for="order in orders.data" :key="order.pl_id">
          <td class="px-5 py-2 border-b border-gray-200 bg-white text-sm">
            {{ order.pl_main_id }}</td>
          <td class="px-5 py-2 border-b border-gray-200
          bg-white text-sm">
            {{order.order_addresses[0].pl_name1}} {{order.order_addresses[0].pl_name2}}
            {{order.order_addresses[0].pl_name3}}
          </td>
          <td class="px-5 py-2 border-b border-gray-200
          bg-white text-sm">
            {{order.created_at | luxon({input: 'iso', output: 'dd.MM.yyyy'})}}
          </td>
          <td class="px-5 py-2 border-b border-gray-200
          bg-white text-sm">
            {{order.cards.length}}
          </td>
        </tr>
      </tbody>
    </table>
    <div class="mt-2 float-right inline-flex items-center">
      <a href="" @click.prevent="toPage = 1; orderIndex()" :class="[orders.current_page <= 1 ? 'text-gray-200': '']"><svg class="w-4 h-4 mt-1 mr-2" aria-hidden="true" focusable="false" data-prefix="fas" data-icon="step-backward" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><path fill="currentColor" d="M64 468V44c0-6.6 5.4-12 12-12h48c6.6 0 12 5.4 12 12v176.4l195.5-181C352.1 22.3 384 36.6 384 64v384c0 27.4-31.9 41.7-52.5 24.6L136 292.7V468c0 6.6-5.4 12-12 12H76c-6.6 0-12-5.4-12-12z"></path></svg></a>
      <a href="" @click.prevent="toPage = prevPage; orderIndex()" :class="[orders.current_page <= 1 ? 'text-gray-200': '']"><svg class="w-3 h-3 mt-1 mr-2 transform rotate-180" aria-hidden="true" focusable="false" data-prefix="fas" data-icon="play" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><path fill="currentColor" d="M424.4 214.7L72.4 6.6C43.8-10.3 0 6.1 0 47.9V464c0 37.5 40.7 60.1 72.4 41.3l352-208c31.4-18.5 31.5-64.1 0-82.6z"></path></svg></a>
      <span>Seite {{orders.current_page}} von {{orders.last_page}}</span>
      <a href="" @click.prevent="toPage = nextPage; orderIndex()" :class="[orders.current_page >= orders.last_page ? 'text-gray-200': '']"><svg class="w-3 h-3 mt-1 ml-2" aria-hidden="true" focusable="false" data-prefix="fas" data-icon="play" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><path fill="currentColor" d="M424.4 214.7L72.4 6.6C43.8-10.3 0 6.1 0 47.9V464c0 37.5 40.7 60.1 72.4 41.3l352-208c31.4-18.5 31.5-64.1 0-82.6z"></path></svg></a>
      <a href="" @click.prevent="toPage = orders.last_page; orderIndex()" :class="[orders.current_page >= orders.last_page ? 'text-gray-200': '']"><svg class="w-4 h-4 mt-1 ml-2" aria-hidden="true" focusable="false" data-prefix="fas" data-icon="step-forward" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><path fill="currentColor" d="M384 44v424c0 6.6-5.4 12-12 12h-48c-6.6 0-12-5.4-12-12V291.6l-195.5 181C95.9 489.7 64 475.4 64 448V64c0-27.4 31.9-41.7 52.5-24.6L312 219.3V44c0-6.6 5.4-12 12-12h48c6.6 0 12 5.4 12 12z"></path></svg></a>
    </div>
  </div>
</template>

<script>
import AnimatedButton from '@/components/Util/AnimatedButton.vue';

export default {
  name: 'Plentymarkets',
  props: {
    users: Array,
  },
  components: {
    AnimatedButton,
  },
  data() {
    return {
      loadState: 'initial',
      lastUpdatedAt: '',
      orders: {
        data: [],
      },
      toPage: 1,
    };
  },
  computed: {
    nextPage() {
      const next = this.orders.current_page + 1;
      if (next >= this.orders.last_page) {
        return this.orders.last_page;
      }
      return next;
    },
    prevPage() {
      const prev = this.orders.current_page - 1;
      if (prev <= 1) {
        return 1;
      }
      return prev;
    },
  },
  methods: {
    plentyIndex() {
      this.$axios.get('plenty',
        {
          headers: { Authorization: `Bearer ${this.$store.getters['auth/authToken']}` },
        })
        .then((response) => {
          this.lastUpdatedAt = response.data.data.updated_at;
        })
        .catch((e) => {
          this.loadState = 'error';
          this.$toast.open({
            message: e,
            type: 'error',
          });
        });
    },
    orderIndex() {
      this.$axios.get('orders',
        {
          params: { page: this.toPage },
          headers: { Authorization: `Bearer ${this.$store.getters['auth/authToken']}` },
        })
        .then((response) => {
          this.orders = response.data.data;
        })
        .catch((e) => {
          this.loadState = 'error';
          this.$toast.open({
            message: e,
            type: 'error',
          });
        });
    },
    retrieveOrders(button) {
      if (typeof this.$refs[button] !== 'undefined') this.$refs[button].loadState = 'loading';
      this.$axios.get('plenty/orders',
        { headers: { Authorization: `Bearer ${this.$store.getters['auth/authToken']}` } })
        .then((response) => {
          this.orderIndex();
          this.orders = response.data.data;
          if (typeof this.$refs[button] !== 'undefined') this.$refs[button].loadState = 'success';
          if (this.orders.length > 0) {
            this.$toast.open({
              message: 'Aufträge erfolgreich geladen!',
              type: 'success',
            });
          } else if (this.orders.length === 0) {
            this.$toast.open({
              message: 'Keine Aufträge gefunden!',
              type: 'info',
            });
          }
        })
        .catch((e) => {
          if (typeof this.$refs[button] !== 'undefined') this.$refs[button].loadState = 'error';
          this.$toast.open({
            message: e,
            type: 'error',
          });
        });
    },
  },
  beforeMount() {
    this.plentyIndex();
    this.orderIndex();
  },
};
</script>

<style scoped>

</style>

<template>
  <label
    :for="name"
    class="flex items-center cursor-pointer"
  >
    <!-- toggle -->
    <div class="relative">
      <!-- input -->
      <input :id="name" type="checkbox" class="hidden" v-model="inputValue" />
      <!-- line -->
      <div
        class="toggle__line w-6 h-2 bg-gray-400 rounded-full shadow-inner"
      ></div>
      <!-- dot -->
      <div
        class="toggle__dot absolute w-4 h-4 bg-white rounded-full shadow inset-y-0 left-0"
      ></div>
    </div>
    <!-- label -->
    <div
      class="ml-4 text-gray-700 font-medium"
    >
      <span v-if="inputValue">{{labelTrue}}</span>
      <span v-if="!inputValue">{{labelFalse}}</span>
    </div>
  </label>
</template>

<script>
export default {
  name: 'ToggleSwitch',
  props: {
    labelTrue: {
      type: String,
      required: true,
    },
    labelFalse: {
      type: String,
      required: true,
    },
    name: {
      type: String,
      required: true,
    },
    val: {
      type: [Number, Boolean],
      required: true,
    },
  },
  data() {
    return {
      inputValue: false,
    };
  },
  watch: {
    val: {
      immediate: true,
      handler(val) {
        this.inputValue = val;
      },
    },
    inputValue: {
      immediate: false,
      handler(value) {
        this.$emit('change', value);
      },
    },
  },
};
</script>

<style scoped>
.toggle__dot {
  top: -.25rem;
  left: -.25rem;
  transition: all 0.3s ease-in-out;
}

input:checked ~ .toggle__dot {
  transform: translateX(100%);
  background-color: #48bb78;
}
</style>

<template>
  <div>
    <table class="mt-5 min-w-full leading-normal">
      <tr>
        <th class="px-5 py-2 border-b-2 border-gray-200 text-left text-xs font-semibold
              text-gray-600 tracking-wider w-5">
          Status
        </th>
        <th class="px-5 py-2 border-b-2 border-gray-200 text-left text-xs font-semibold
              text-gray-600 tracking-wider w-30">
          Name
        </th>
        <th class="px-5 py-2 border-b-2 border-gray-200 text-left text-xs font-semibold
              text-gray-600 tracking-wider w-30">
          Mit Abfrage?
        </th>
        <th class="px-5 py-2 border-b-2 border-gray-200 text-left text-xs font-semibold
              text-gray-600 tracking-wider w-23">
          Aktion
        </th>
      </tr>
      <tr v-for="status in statusList" :key="status.id">
        <td class="px-5 py-2 border-b border-gray-200 bg-white text-sm w-5">
          {{status.status}}
        </td>
        <td class="px-5 py-2 border-b border-gray-200 bg-white text-sm w-30">
          {{status.name}}
        </td>
        <td class="px-5 py-2 border-b border-gray-200 bg-white text-sm w-30">
          {{getConfirmationStatus(status.confirmation_required)}}
        </td>
        <td class="flex px-5 py-2 border-b inline-block border-gray-200 bg-white text-sm w-30">
          <span @click="activeStatus = status; $refs['modal-edit'].isShown = true;"
            class="flex cursor-pointer text-blue-500 hover:text-blue-700 mr-2">
            <svg class="w-4 h-4 mr-1 mt-0.5" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor"><path d="M17.414 2.586a2 2 0 00-2.828 0L7 10.172V13h2.828l7.586-7.586a2 2 0 000-2.828z" /><path fill-rule="evenodd" d="M2 6a2 2 0 012-2h4a1 1 0 010 2H4v10h10v-4a1 1 0 112 0v4a2 2 0 01-2 2H4a2 2 0 01-2-2V6z" clip-rule="evenodd" /></svg>
            Bearbeiten</span>
        </td>
      </tr>
    </table>
    <ModalSm ref="modal-edit" customClass="h-30 w-80">
      <template v-slot:header>
        Status bearbeiten
      </template>
      <template v-slot:body>
        <ToggleSwitch class="mt-2"
                      labelTrue="Mit Abfrage" labelFalse="Ohne Abfrage"
                      :val="slotStatus.confirmation_required"
                      v-model="slotStatus.confirmation_required" name="confirmation_required"
                      @change="(value) => slotStatus.confirmation_required = value"
        ></ToggleSwitch>
      </template>
      <template v-slot:footer>
        <AnimatedButton @click.native="statusUpdate('button-edit-'+slotStatus.id)"
                        :ref="'button-edit-'+slotStatus.id"
                        :pause="750"
                        @reset="$refs['modal-edit'].isShown = false"
                        class="ml-2 float-right">
        </AnimatedButton>
      </template>
    </ModalSm>
  </div>
</template>

<script>
import ModalSm from '@/components/Util/ModalSm.vue';
import ToggleSwitch from '@/components/Util/ToggleSwitch.vue';
import AnimatedButton from '@/components/Util/AnimatedButton.vue';

export default {
  name: 'Status',
  components: {
    AnimatedButton,
    ModalSm,
    ToggleSwitch,
  },
  data() {
    return {
      statusList: [],
      activeStatus: {},
      slotStatus: {},
    };
  },
  computed: {
  },
  methods: {
    getRoleUi(role) {
      switch (role) {
        case 'admin':
          return 'Admin';
        case 'user':
          return 'Benutzer';
        default:
          return 'Benutzer';
      }
    },
    getConfirmationStatus(value) {
      if (value) {
        return 'Ja';
      } return 'Nein';
    },
    index() {
      this.$axios.get('status',
        { headers: { Authorization: `Bearer ${this.$store.getters['auth/authToken']}` } })
        .then((response) => {
          this.statusList = response.data.data;
        })
        .catch(() => {
          // console.log(error);
        });
    },
    statusUpdate(button) {
      if (typeof this.$refs[button] !== 'undefined') this.$refs[button].loadState = 'loading';
      this.$axios.put(`status/${this.slotStatus.id}`, this.slotStatus,
        { headers: { Authorization: `Bearer ${this.$store.getters['auth/authToken']}` } })
        .then((response) => {
          if (typeof this.$refs[button] !== 'undefined') this.$refs[button].loadState = 'success';
          const index = this.statusList.findIndex((u) => u.id === response.data.data.id);
          this.statusList.splice(index, 1, response.data.data);
          this.$toast.open({
            message: 'Erfolgreich gespeichert!',
            type: 'success',
          });
        })
        .catch((e) => {
          if (typeof this.$refs[button] !== 'undefined') this.$refs[button].loadState = 'error';
          this.$toast.open({
            message: e,
            type: 'error',
          });
        });
    },
  },
  mounted() {
    this.index();
  },
  watch: {
    activeStatus(value) {
      this.slotStatus = { ...value };
    },
  },
};
</script>

<style scoped>

</style>

<template>
<div class="h-full">
  <div class="flex flex-row h-full gap-10">
    <div class="flex-1 flex flex-col block h-full w-full">
      <div class="mb-5">Verfügbare Spalten</div>
      <div class="flex w-full min-h-56 max-h-full overflow-auto z-10">
        <table class="min-w-full leading-normal">
        <tr>
          <th class="px-5 py-2 border-b-2 border-gray-200 text-left text-xs font-semibold
              text-gray-600 tracking-wider w-11/12">
            Name
          </th>
          <th class="px-5 py-2 border-b-2 border-gray-200 text-left text-xs font-semibold
              text-gray-600 tracking-wider w-5">
            Aktion
          </th>
        </tr>
        <tr
            v-for="column in orderedColumns" :key="column.id">
          <td class="px-5 py-2 border-b border-gray-200 bg-white text-sm w-8/12">
            {{ column.name }}</td>
          <td class="px-5 py-2 border-b border-gray-200 bg-white
              text-sm w-5 handle">
            <template>
              <div @click="setActiveProfile(column); profileStore('ib'+_uid);" :ref="'ib'+_uid"
                   class="bg-white flex text-gray-600
                flex-shrink-0focus-within:border-blue-500 cursor-pointer"
                   v-if="!columnInProfiles.includes(column.id)">
                <svg class="w-4 h-4  m-auto " xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M17 8l4 4m0 0l-4 4m4-4H3" /></svg>
              </div>
            </template>
          </td>
        </tr>
      </table>
      </div>
    </div>
    <div class="flex-1 flex flex-col block h-full w-full">
      <div class="mb-5">Ausgewählte Spalten</div>
      <div class="flex w-full min-h-56 max-h-full overflow-auto z-10">
        <table class="min-w-full leading-normal">
        <tr>
          <th class="px-5 py-2 border-b-2 border-gray-200 text-left text-xs font-semibold
              whitespace-nowrap text-gray-600 tracking-wider w-5">
            Originalname
          </th>
          <th class="px-5 py-2 border-b-2 border-gray-200 text-left text-xs font-semibold
              whitespace-nowrap text-gray-600 tracking-wider w-6/12 whitespace-nowrap">
            Benutzerdefinierter Name
          </th>
          <th class="px-5 py-2 border-b-2 border-gray-200 text-left text-xs font-semibold
              text-gray-600 tracking-wider w-30">
            Aktion
          </th>
        </tr>
        <tr v-for="profile in orderedProfiles" :key="profile.id">
          <td class="cursor-move px-5 py-2 border-b border-gray-200 bg-white text-sm w-4/12
           whitespace-nowrap">
            {{profile.name}}
          </td>
          <td class="px-5 py-2 border-b border-gray-200 bg-white text-sm w-4/12 whitespace-nowrap">
            {{ profile.custom_name }}</td>
          <td class="flex px-5 py-2 inline-block border-b border-gray-200
          bg-white text-sm w-30">
            <span @click="activeProfile = profile; $refs['modal-edit'].isShown = true;"
                  class="flex cursor-pointer text-blue-500 hover:text-blue-700 mr-3">
            <svg class="w-4 h-4 mr-1 mt-0.5" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor"><path d="M17.414 2.586a2 2 0 00-2.828 0L7 10.172V13h2.828l7.586-7.586a2 2 0 000-2.828z" /><path fill-rule="evenodd" d="M2 6a2 2 0 012-2h4a1 1 0 010 2H4v10h10v-4a1 1 0 112 0v4a2 2 0 01-2 2H4a2 2 0 01-2-2V6z" clip-rule="evenodd" /></svg>
            Bearbeiten</span>
            <span @click="activeProfile = profile; $refs['modal-delete'].isShown = true;"
                  class="flex cursor-pointer text-red-500 hover:text-red-700">
            <svg class="w-4 h-4 mt-0.5 mr-1" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16" /></svg>
            Entfernen</span>
          </td>
        </tr>
      </table>
      </div>
    </div>
  </div>
  <Modal ref="modal-edit" customClass="h-64 w-80">
    <template v-slot:header>
      Spalte bearbeiten
    </template>
    <template v-slot:body>
      <InputCom name="columnName" label="Name" v-model="slotProfile.custom_name"
                :val="slotProfile.custom_name"></InputCom>
    </template>
    <template v-slot:footer>
      <AnimatedButton @click.native="profileUpdate('button-edit-'+slotProfile.id)"
                      :ref="'button-edit-'+slotProfile.id"
                      :pause="750"
                      @reset="$refs['modal-edit'].isShown = false"
                      class="ml-2 float-right">
      </AnimatedButton>
    </template>
  </Modal>
  <Modal ref="modal-delete"
         customClass="h-30 w-80">
    <template v-slot:header>
      Spalte entfernen
    </template>
    <template v-slot:body>
      <p class="text-gray-700 font-sans tracking-wide text-sm">Möchten Sie die Spalte wirklich
        aus Ihrem Profil entfernen?</p>
    </template>
    <template v-slot:footer>
      <AnimatedButton @click.native="profileRemove('button-delete-'+slotProfile.id);"
                      :ref="'button-delete-'+slotProfile.id"
                      on-initial="Entfernen"
                      initial-class="bg-red-500 hover:bg-red-700"
                      load-class="bg-red-500 cursor-wait"
                      on-success="Spalte entfernt!"
                      :pause="1000"
                      @reset="$refs['modal-delete'].isShown = false"
                      class="ml-2 float-right">
      </AnimatedButton>
    </template>
  </Modal>
</div>
</template>

<script>
import Modal from '@/components/Util/Modal.vue';
import InputCom from '@/components/Util/InputCom.vue';
import AnimatedButton from '@/components/Util/AnimatedButton.vue';

export default {
  name: 'Profile',
  components: {
    Modal,
    InputCom,
    AnimatedButton,
  },
  props: {
    userId: {
      type: Number,
      required: true,
    },
  },
  computed: {
    orderedColumns() {
      const col = this.columns;
      return col.sort((a, b) => a.position - b.position);
    },
    orderedProfiles() {
      const col = this.profiles;
      return col.sort((a, b) => a.position - b.position);
    },
    columnInProfiles() {
      const colInProfiles = [];
      this.profiles.forEach((p) => {
        colInProfiles.push(p.column_id);
      });
      return colInProfiles;
    },
  },
  data() {
    return {
      columns: [],
      profiles: [],
      modalOpen: false,
      modalOpenDelete: false,
      loadState: 'initial',
      activeProfile: {},
      slotProfile: {},
    };
  },
  methods: {
    columnIndex() {
      this.$axios.get('columns',
        { headers: { Authorization: `Bearer ${this.$store.getters['auth/authToken']}` } })
        .then((response) => {
          this.columns = response.data.data;
        })
        .catch(() => {
          // console.log(error);
        });
    },
    profileIndex() {
      this.$axios.get(`profiles/user/${this.userId}`,
        { headers: { Authorization: `Bearer ${this.$store.getters['auth/authToken']}` } })
        .then((response) => {
          this.profiles = response.data.data;
        })
        .catch(() => {
          // console.log(error);
        });
    },
    profileStore(button) {
      if (typeof this.$refs[button] !== 'undefined') this.$refs[button].loadState = 'loading';
      this.$axios.post('profiles', this.activeProfile,
        { headers: { Authorization: `Bearer ${this.$store.getters['auth/authToken']}` } })
        .then((response) => {
          if (typeof this.$refs[button] !== 'undefined') this.$refs[button].loadState = 'success';
          this.$toast.open({
            message: 'Erfolgreich gespeichert!',
            type: 'success',
          });
          this.profiles.push(response.data.data);
        })
        .catch((error) => {
          if (typeof this.$refs[button] !== 'undefined') this.$refs[button].loadState = 'error';
          this.$toast.open({
            message: error,
            type: 'error',
          });
        });
    },
    // eslint-disable-next-line no-unused-vars
    profileUpdate(button) {
      if (typeof this.$refs[button] !== 'undefined') this.$refs[button].loadState = 'loading';
      this.$axios.put(`profiles/${this.activeProfile.id}`, this.slotProfile,
        { headers: { Authorization: `Bearer ${this.$store.getters['auth/authToken']}` } })
        .then((response) => {
          const index = this.profiles.findIndex((p) => p.id === response.data.data.id);
          this.profiles.splice(index, 1, response.data.data);
          if (typeof this.$refs[button] !== 'undefined') this.$refs[button].loadState = 'success';
          this.$toast.open({
            message: 'Erfolgreich gespeichert!',
            type: 'success',
          });
        })
        .catch((error) => {
          if (typeof this.$refs[button] !== 'undefined') this.$refs[button].loadState = 'error';
          this.$toast.open({
            message: error,
            type: 'error',
          });
        });
    },
    profileRemove(button) {
      if (typeof this.$refs[button] !== 'undefined') this.$refs[button].loadState = 'loading';
      this.$axios.delete(`profiles/${this.activeProfile.id}`,
        { headers: { Authorization: `Bearer ${this.$store.getters['auth/authToken']}` } })
        .then(() => {
          const index = this.profiles.findIndex((p) => p.id === this.activeProfile.id);
          this.profiles.splice(index, 1);
          if (typeof this.$refs[button] !== 'undefined') this.$refs[button].loadState = 'success';
          this.$toast.open({
            message: 'Spalte erfolgreich entfernt!',
            type: 'success',
          });
        })
        .catch((error) => {
          if (typeof this.$refs[button] !== 'undefined') this.$refs[button].loadState = 'error';
          this.$toast.open({
            message: error,
            type: 'error',
          });
        });
    },
    setActiveProfile(col) {
      this.activeProfile = {
        name: col.name,
        is_open: 1,
        custom_name: col.name,
        column_id: col.id,
        position: col.position,
        user_id: this.userId,
      };
    },
  },
  mounted() {
    this.columnIndex();
    this.profileIndex();
  },
  watch: {
    activeProfile(value) {
      this.slotProfile = { ...value };
    },
  },
};
</script>

<style scoped>

</style>

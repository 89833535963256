<template>
  <div class="flex flew-row">
    <div class="flex-1">
      <Reklamationsgrund></Reklamationsgrund>
    </div>
    <div class="flex-1 ml-5">
      <Reklamationsstatus></Reklamationsstatus>
    </div>
  </div>
</template>

<script>
import Reklamationsgrund from '@/components/admin/Reklamationsgrund.vue';
import Reklamationsstatus from '@/components/admin/Reklamationsstatus.vue';

export default {
  name: 'Reklamation',
  components: {
    Reklamationsstatus,
    Reklamationsgrund,
  },

};
</script>

<style scoped>

</style>

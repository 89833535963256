<template>
  <div>
    <button @click="$refs['modal-new'].isShown = true; activeUser = {}"
            class="rounded text-gray-100 px-3 py-1
    float-right hover:shadow-inner align-middle inline-flex items-center
    bg-blue-500 hover:bg-blue-700">
      Neuer Benutzer</button>
    <table class="mt-5 min-w-full leading-normal">
      <tr>
        <th class="px-5 py-2 border-b-2 border-gray-200 text-left text-xs font-semibold
              text-gray-600 tracking-wider w-5">
          #
        </th>
        <th class="px-5 py-2 border-b-2 border-gray-200 text-left text-xs font-semibold
              text-gray-600 tracking-wider w-30">
          Benutzername
        </th>
        <th class="px-5 py-2 border-b-2 border-gray-200 text-left text-xs font-semibold
              text-gray-600 tracking-wider w-30">
          Name
        </th>
        <th class="px-5 py-2 border-b-2 border-gray-200 text-left text-xs font-semibold
              text-gray-600 tracking-wider w-30">
          Vorname
        </th>
        <th class="px-5 py-2 border-b-2 border-gray-200 text-left text-xs font-semibold
              text-gray-600 tracking-wider w-30">
          Rolle
        </th>
        <th class="px-5 py-2 border-b-2 border-gray-200 text-left text-xs font-semibold
              text-gray-600 tracking-wider w-23">
          Aktion
        </th>
      </tr>
      <tr v-for="(user, index) in users" :key="user.name">
        <td class="px-5 py-2 border-b border-gray-200 bg-white text-sm w-5">
          {{index + 1}}
        </td>
        <td class="px-5 py-2 border-b border-gray-200 bg-white text-sm w-30">
          {{user.username}}
        </td>
        <td class="px-5 py-2 border-b border-gray-200 bg-white text-sm w-30">
          {{user.first_name}}
        </td>
        <td class="px-5 py-2 border-b border-gray-200 bg-white text-sm w-30">
          {{user.last_name}}
        </td>
        <td class="px-5 py-2 border-b border-gray-200 bg-white text-sm w-30">
          {{getRoleUi(user.role)}}
        </td>
        <td class="flex px-5 py-2 border-b inline-block border-gray-200 bg-white text-sm w-30">
          <span @click="activeUser = user; $refs['modal-edit'].isShown = true;"
            class="flex cursor-pointer text-blue-500 hover:text-blue-700 mr-2">
            <svg class="w-4 h-4 mr-1 mt-0.5" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor"><path d="M17.414 2.586a2 2 0 00-2.828 0L7 10.172V13h2.828l7.586-7.586a2 2 0 000-2.828z" /><path fill-rule="evenodd" d="M2 6a2 2 0 012-2h4a1 1 0 010 2H4v10h10v-4a1 1 0 112 0v4a2 2 0 01-2 2H4a2 2 0 01-2-2V6z" clip-rule="evenodd" /></svg>
            Bearbeiten</span>
          <span @click="activeUser = user; $refs['modal-delete'].isShown = true;"
            class="flex cursor-pointer text-red-500 hover:text-red-700 border-l mr-2 pl-2">
            <svg class="w-4 h-4 mt-0.5 mr-1" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16" /></svg>
            Entfernen</span>
          <span @click="activeUser = user; $refs['modal-secret'].isShown = true;"
            class="flex cursor-pointer text-blue-500 hover:text-blue-700 mr-3 border-l pl-2">
            <svg class="w-4 h-4 mr-1 mt-0.5" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor"><path fill-rule="evenodd" d="M5 9V7a5 5 0 0110 0v2a2 2 0 012 2v5a2 2 0 01-2 2H5a2 2 0 01-2-2v-5a2 2 0 012-2zm8-2v2H7V7a3 3 0 016 0z" clip-rule="evenodd" /></svg>
            Passwort zurücksetzen
          </span>
          <span @click="activeUser = user; $refs['modal-profile'].isShown = true;"
                class="flex cursor-pointer text-blue-500 hover:text-blue-700 mr-3 border-l pl-2">
            <svg class="w-4 h-4 mr-1 mt-0.5" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9 17V7m0 10a2 2 0 01-2 2H5a2 2 0 01-2-2V7a2 2 0 012-2h2a2 2 0 012 2m0 10a2 2 0 002 2h2a2 2 0 002-2M9 7a2 2 0 012-2h2a2 2 0 012 2m0 10V7m0 10a2 2 0 002 2h2a2 2 0 002-2V7a2 2 0 00-2-2h-2a2 2 0 00-2 2" /></svg>
            Profil bearbeiten
          </span>
        </td>
      </tr>
    </table>
    <ModalSm ref="modal-new" customClass="h-30 w-80">
      <template v-slot:header>
        Benutzer hinzufügen
      </template>
      <template v-slot:body>
        <InputCom name="username" label="Benutzername" v-model="slotUser.username"
                  :val="slotUser.username"></InputCom>
        <InputCom name="first_name" label="Vorname" v-model="slotUser.first_name"
                  :val="slotUser.first_name"></InputCom>
        <InputCom name="last_name" label="Nachname" v-model="slotUser.last_name"
                  :val="slotUser.last_name"></InputCom>
        <SelectCom name="role" label="Rolle" v-model="slotUser.role"
                   :options="[{name: 'Admin', db: 'admin'}, {name: 'Benutzer', db: 'user'}]"
                   :val="slotUser.role"></SelectCom>
        <InputCom name="password" label="Passwort" v-model="slotUser.password"
                  input-type="password" :val="slotUser.password"></InputCom>
      </template>
      <template v-slot:footer>
        <AnimatedButton @click.native="userStore('button-new-'+slotUser.id)"
                        :ref="'button-new-'+slotUser.id"
                        :pause="750"
                        @reset="$refs['modal-new'].isShown = false"
                        class="ml-2 float-right">
        </AnimatedButton>
      </template>
    </ModalSm>
    <ModalSm ref="modal-edit" customClass="h-30 w-80">
      <template v-slot:header>
        Benutzer bearbeiten
      </template>
      <template v-slot:body>
        <InputCom name="username" label="Benutzername" v-model="slotUser.username"
                  :val="slotUser.username"></InputCom>
        <InputCom name="first_name" label="Vorname" v-model="slotUser.first_name"
                  :val="slotUser.first_name"></InputCom>
        <InputCom name="last_name" label="Nachname" v-model="slotUser.last_name"
                  :val="slotUser.last_name"></InputCom>
        <SelectCom name="role" label="Rolle" v-model="slotUser.role"
                   :options="[{name: 'Admin', db: 'admin'}, {name: 'Benutzer', db: 'user'}]"
                  :val="slotUser.role"></SelectCom>
      </template>
      <template v-slot:footer>
        <AnimatedButton @click.native="userUpdate('button-edit-'+slotUser.id)"
                        :ref="'button-edit-'+slotUser.id"
                        :pause="750"
                        @reset="$refs['modal-edit'].isShown = false"
                        class="ml-2 float-right">
        </AnimatedButton>
      </template>
    </ModalSm>
    <ModalSm ref="modal-secret" customClass="h-30 w-80">
      <template v-slot:header>
        Passwort ändern
      </template>
      <template v-slot:body>
        <InputCom name="old_password" label="Ihr Passwort" v-model="slotUser.old_password"
                  :val="slotUser.old_password" input-type="password"></InputCom>
        <InputCom name="new_password" label="Neues Passwort" v-model="slotUser.new_password"
                  :val="slotUser.new_password" input-type="password"></InputCom>
        <InputCom name="new_password_confirmation" label="Neues Passwort wiederholen"
                  v-model="slotUser.new_password_confirmation"
                  :val="slotUser.new_password_confirmation" input-type="password"></InputCom>
      </template>
      <template v-slot:footer>
        <AnimatedButton @click.native="userChangeSecret('button-secret-'+slotUser.id)"
                        :ref="'button-secret-'+slotUser.id"
                        on-initial="Passwort ändern"
                        :pause="750"
                        @reset="$refs['modal-secret'].isShown = false"
                        class="ml-2 float-right">
        </AnimatedButton>
      </template>
    </ModalSm>
    <ModalSm ref="modal-delete" customClass="h-30 w-100">
      <template v-slot:header>
        Spalte löschen
      </template>
      <template v-slot:body>
        <p>Möchten Sie den Benutzer <span class="text-red-500">{{ slotUser.first_name }}
          {{ slotUser.last_name }} ({{ slotUser.username }})
        </span> wirklich löschen?</p>
      </template>
      <template v-slot:footer>
        <AnimatedButton @click.native="userDelete('button-delete-'+slotUser.id)"
                        :ref="'button-delete-'+slotUser.id"
                        on-initial="Ja, Benutzer löschen"
                        on-error="Fehlgeschlagen!"
                        on-success="Erfolgreich gelöscht!"
                        initial-class="bg-red-500 hover:bg-red-700"
                        :pause="750"
                        @reset="$refs['modal-delete'].isShown = false"
                        class="ml-2 float-right">
        </AnimatedButton>
      </template>
    </ModalSm>
    <Modal ref="modal-profile" customClass="h-full w-full">
      <template v-slot:header>
        Profil bearbeiten
      </template>
      <template v-slot:body>
        <Profile :user-id="activeUser.id"></Profile>
      </template>
      <template v-slot:footer>
        <span></span>
      </template>
    </Modal>
  </div>
</template>

<script>
import Modal from '@/components/Util/Modal.vue';
import ModalSm from '@/components/Util/ModalSm.vue';
import InputCom from '@/components/Util/InputCom.vue';
import SelectCom from '@/components/Util/SelectCom.vue';
import AnimatedButton from '@/components/Util/AnimatedButton.vue';
import Profile from '@/components/settings/Profile.vue';

export default {
  name: 'Users',
  components: {
    Profile,
    AnimatedButton,
    Modal,
    ModalSm,
    InputCom,
    SelectCom,
  },
  data() {
    return {
      users: [],
      activeUser: {},
      slotUser: {},
      mounted: false,
    };
  },
  computed: {
    getHeight() {
      if (this.users.length > 0) {
        if (this.$refs['modal-profile'].$refs.length > 0) {
          return this.$refs['modal-profile'].$refs['modal-body'].clientHeight;
        }
      }
      return 0;
    },
  },
  methods: {
    parentHeight(parent) {
      console.log(parent);
      console.log(this.$refs['modal-profile'].$refs);
      // console.log(this.$refs['modal-profile'].$slots.body[0].data.style);
      console.log(parent.$el.clientHeight);
      return parent.$el.offsetHeight;
    },
    getRoleUi(role) {
      switch (role) {
        case 'admin':
          return 'Admin';
        case 'user':
          return 'Benutzer';
        default:
          return 'Benutzer';
      }
    },
    index() {
      this.$axios.get('users',
        { headers: { Authorization: `Bearer ${this.$store.getters['auth/authToken']}` } })
        .then((response) => {
          this.users = response.data.data;
        })
        .catch(() => {
          // console.log(error);
        });
    },
    userUpdate(button) {
      if (typeof this.$refs[button] !== 'undefined') this.$refs[button].loadState = 'loading';
      this.$axios.put(`users/${this.slotUser.id}`, this.slotUser,
        { headers: { Authorization: `Bearer ${this.$store.getters['auth/authToken']}` } })
        .then((response) => {
          if (typeof this.$refs[button] !== 'undefined') this.$refs[button].loadState = 'success';
          const index = this.users.findIndex((u) => u.id === response.data.data.id);
          this.users.splice(index, 1, response.data.data);
          this.$toast.open({
            message: 'Erfolgreich gespeichert!',
            type: 'success',
          });
        })
        .catch((e) => {
          if (typeof this.$refs[button] !== 'undefined') this.$refs[button].loadState = 'error';
          this.$toast.open({
            message: e,
            type: 'error',
          });
        });
    },
    userChangeSecret(button) {
      if (typeof this.$refs[button] !== 'undefined') this.$refs[button].loadState = 'loading';
      this.$axios.post(`change_secret/${this.slotUser.id}`, this.slotUser,
        { headers: { Authorization: `Bearer ${this.$store.getters['auth/authToken']}` } })
        .then(() => {
          if (typeof this.$refs[button] !== 'undefined') this.$refs[button].loadState = 'success';
          this.$toast.open({
            message: 'Erfolgreich gespeichert!',
            type: 'success',
          });
        })
        .catch((e) => {
          if (typeof this.$refs[button] !== 'undefined') this.$refs[button].loadState = 'error';
          this.$toast.open({
            message: e,
            type: 'error',
          });
        });
    },
    userDelete(button) {
      if (typeof this.$refs[button] !== 'undefined') this.$refs[button].loadState = 'loading';
      this.$axios.delete(`users/${this.activeUser.id}`,
        { headers: { Authorization: `Bearer ${this.$store.getters['auth/authToken']}` } })
        .then(() => {
          const index = this.users.findIndex((u) => u.id === this.activeUser.id);
          this.users.splice(index, 1);
          if (typeof this.$refs[button] !== 'undefined') this.$refs[button].loadState = 'success';
          this.$toast.open({
            message: 'Erfolgreich gelöscht!',
            type: 'success',
          });
        })
        .catch((error) => {
          if (typeof this.$refs[button] !== 'undefined') this.$refs[button].loadState = 'error';
          this.$toast.open({
            message: error.response.data.error,
            type: 'error',
          });
        });
    },
    userStore(button) {
      if (typeof this.$refs[button] !== 'undefined') this.$refs[button].loadState = 'loading';
      this.$axios.post('users', this.slotUser,
        { headers: { Authorization: `Bearer ${this.$store.getters['auth/authToken']}` } })
        .then((response) => {
          if (typeof this.$refs[button] !== 'undefined') this.$refs[button].loadState = 'success';
          this.users.push(response.data.data);
          this.$toast.open({
            message: 'Erfolgreich gespeichert!',
            type: 'success',
          });
        })
        .catch((error) => {
          if (typeof this.$refs[button] !== 'undefined') this.$refs[button].loadState = 'error';
          this.$toast.open({
            message: error.response.data,
            type: 'error',
          });
        });
    },
  },
  mounted() {
    this.index();
  },
  watch: {
    activeUser(value) {
      this.slotUser = { ...value };
    },
  },
};
</script>

<style scoped>

</style>
